<script>
import BaseView from "@/views/BaseView.vue";
import { DateTimeMixin } from "@/mixins/date-time.mixin";
import DatePicker from "@/components/pickers/DatePicker.vue";
import TimePicker from "@/components/pickers/TimePicker.vue";
import TableFilter from "@/components/tableFilter/TableFilter.vue";
import TableFilterRow from "@/components/tableFilter/TableFilterRow.vue";
import { mapActions, mapState } from "pinia";
import { useAppStore } from "@/store/app.store";
import SearchField from "@/components/inputs/SearchField.vue";
import { useSnackbarStore } from "@/store/snackbar.store";

export default {
  name: "TrucksHistorySearch",
  components: { SearchField, TableFilterRow, TableFilter, TimePicker, DatePicker },
  mixins: [BaseView, DateTimeMixin],
  emits: ["search"],
  data() {
    return {
      params: { number: "", dateFrom: this.$dayjs().format(), dateTo: this.$dayjs().add(1, "days").format() },
      today: this.$dayjs().format("YYYY-MM-DD"),
    };
  },
  computed: {
    ...mapState(useAppStore, ["dispatcher"]),
  },
  mounted() {
    if (!this.params.dateFrom) {
      this.params.dateFrom = this.$dayjs().format();
    }
    if (!this.params.dateTo) {
      this.params.dateTo = this.$dayjs().add(1, "days").format();
    }
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showSnackbarError"]),
    clear() {
      this.params = { number: "", dateFrom: this.$dayjs().format(), dateTo: this.$dayjs().add(1, "days").format() };
      this.search();
    },
    search() {
      const dtFrom = this.dateToUTC(
        this.params.dateFrom + "T" + (this.params.timeFrom || "00:00"),
        this.dispatcher.timezone,
      );
      const dtTo = this.dateToUTC(this.params.dateTo + "T" + (this.params.timeTo || "00:00"), this.dispatcher.timezone);
      if (dtTo.diff(dtFrom, "days") > 4) {
        this.showSnackbarError("Date range should not exceed 4 days");
        this.params.dateTo = this.$dayjs(this.params.dateFrom).add(4, "days").format();
        return;
      }

      const params = {
        number: this.params.number,
        dateFrom: dtFrom.format(this.systemDateTimeFormat),
        dateTo: dtTo.format(this.systemDateTimeFormat),
      };
      this.$emit("search", params);
    },
  },
};
</script>

<template>
  <table-filter :expandable="false" @apply="search" @clear="clear">
    <template #header>
      <table-filter-row :divider="false" :label="$t('general.filter-main')" icon="mdi-filter-variant">
        <v-col cols="2">
          <search-field v-model="params.number" label="Truck number" data-qa="number" @search="search" />
        </v-col>
        <v-col cols="2">
          <date-picker v-model="params.dateFrom" :label="'Date from'" @search="search" />
        </v-col>
        <v-col cols="2">
          <time-picker v-model="params.timeFrom" :label="'Time from'" @search="search" />
        </v-col>
        <v-col cols="2">
          <date-picker v-model="params.dateTo" :label="'Date to'" @search="search" />
        </v-col>
        <v-col cols="2">
          <time-picker v-model="params.timeTo" :label="'Time to'" @search="search" />
        </v-col>
      </table-filter-row>
    </template>
  </table-filter>
</template>
