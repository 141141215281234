<script>
import TrucksHistorySearch from "@/views/trucks/history/TrucksHistorySearch.vue";
import BaseView from "@/views/BaseView.vue";
import TruckLocationsHistory from "@/views/trucks/history/TruckLocationsHistory.vue";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import PageHeader from "@/components/PageHeader.vue";
import { mapActions } from "pinia";
import { useSnackbarStore } from "@/store/snackbar.store";

export default {
  name: "TrucksHistory",
  components: { PageHeader, LoadingOverlay, TruckLocationsHistory, TrucksHistorySearch },
  mixins: [BaseView],
  data() {
    return {
      data: null,
      filter: {},
      isLoading: false,
    };
  },
  methods: {
    ...mapActions(useSnackbarStore, ["showSnackbarError", "showSnackbarSuccess"]),
    async refresh() {
      const params = { ...this.filter, ...this.params };
      if (!params.number) return;

      this.isLoading = true;

      // load trucks by number
      let truckID = null;
      try {
        const trucksResp = await this.$api.trucks.findTrucks({ number: params.number });
        switch (trucksResp.data.items.length) {
          case 0:
            this.showSnackbarError(`Truck with number ${params.number} not found`);
            break;
          case 1:
            truckID = trucksResp.data.items[0].id;
            break;
          default:
            this.showSnackbarError(
              `More than one trucks found with number ${params.number}. Please, specify truck number`,
            );
            break;
        }
      } catch (error) {
        console.error("Error loading trucks list: ", error);
      }
      if (!truckID) {
        this.isLoading = false;
        return;
      }

      try {
        const resp = await this.$api.trucks.getTruckLocations(truckID, params.dateFrom, params.dateTo);
        this.data = resp.data;
      } catch (error) {
        console.error("Error loading locations list: ", error);
      } finally {
        this.isLoading = false;
      }
    },
    async search(params) {
      this.params.page = 1;
      this.filter = params;

      await this.refresh();
    },
  },
};
</script>

<template>
  <v-container :fluid="true" class="pt-6 pb-10 pl-6 pr-6 locations-history">
    <page-header :header="$t('trucks.history')"></page-header>
    <trucks-history-search @search="search" />
    <v-col class="fill-height">
      <truck-locations-history :data="data" />
    </v-col>
    <loading-overlay :loading="isLoading" />
  </v-container>
</template>

<style lang="scss" scoped>
.locations-history {
  height: calc(100vh - 60px);
  position: relative;
}
</style>
