import type { API } from "@/api/api";
import type { RouteLocationNormalizedLoaded } from "vue-router";
import type {
  Trip,
  TripOrder,
  TripOrderStop,
  TripRoute,
  TripRouteFreight,
  TripRouteStop,
} from "@/views/trips/edit/trip";
import type { TruckDto } from "@/api/trucks/dto/truck.dto";
import { TravelOrderCancellationStatusMap, TravelOrderStatus } from "@/data/trip";
import type { OrderShortInfoDto, TravelOrderDto } from "@/api/trips/dto/trip.dto";
import { OrderStopState, OrderStopType } from "@/data/order";
import { customAlphabet } from "nanoid";
import type { OrderFacilityDto } from "@/api/orders/dto/order.dto";
import { RouteRedispatchState, RouteStopState } from "@/enums/trip";

export class TripManager {
  private api: API;
  private route: RouteLocationNormalizedLoaded;

  private newTripSlug = "new";

  trip: Trip = { orders: [], routes: [] };

  constructor(api: API, route: RouteLocationNormalizedLoaded) {
    this.api = api;
    this.route = route;
  }

  get orders(): TripOrder[] {
    return this.trip.orders;
  }

  get routes(): TripRoute[] {
    return this.trip.routes;
  }

  get orderStops(): TripOrderStop[] {
    return this.orders.flatMap((o) => o.orderStops);
  }

  get routeFreights(): TripRouteFreight[] {
    return this.trip.routes.flatMap((r) => r.freights);
  }

  async load(): Promise<void> {
    if (this.isNewTrip) {
      return this.loadNewTrip();
    }
    return this.loadExistingTrip();
  }

  private async loadNewTrip(): Promise<void> {
    if (!this.route.query.order || !this.route.query.truck) {
      throw new Error("Missing order or truck for new trip");
    }
    await this.loadOrder(this.route.query!.order.toString());
    await this.loadTruck(parseInt(this.route.query!.truck.toString()));
  }

  private async loadExistingTrip(): Promise<void> {
    if (!this.route.params.id) {
      throw new Error("Missing trip id");
    }
    await this.loadTrip(parseInt(this.route.params.id.toString()));
  }

  async loadTrip(tripId: number) {
    const resp = await this.api.trips.getTripById(tripId);
    if (!resp.success) {
      throw new Error("Trip not found");
    }
    const trip = resp.data!;
    const routes: TripRoute[] = trip.routes.map((r) => ({
      ...r,
      routeStops: r.routeStops.map((rs) => ({ ...rs, use: true, name: "", number: 0, editable: true })),
    }));

    // calculate max stops count in column
    const maxStopsCount = Math.max(...routes.map((r) => r.routeStops[r.routeStops.length - 1].position));

    // collect orders
    for (const route of routes) {
      // route dispatched because it was saved
      route.isDispatched = route.redispatch !== RouteRedispatchState.needed;

      // load truck
      if (route.truckId) {
        const resp = await this.api.trucks.findTruckById(route!.truckId);
        if (resp.success) {
          route.truck = resp.data;
        } else {
          throw new Error("Truck not found");
        }
      }

      // load orders & update travel orders
      this.trip.orders = [];
      const orders: Record<string, OrderShortInfoDto> = {};
      for (const travelOrder of route.travelOrders) {
        if (orders[travelOrder.orderId] === undefined) {
          const orderResponse = await this.api.orders.findOrderById(travelOrder.orderId);
          if (orderResponse.success) {
            const order = orderResponse.data!;
            orders[order.id!] = { id: order.id!, number: order.number!, status: order.status! };
            // add order only once
            this.addOrder({
              ...order,
              files: [],
              number: order.number!,
              freights: order.freights.map((f, i) => ({ ...f, number: i + 1 })),
            });
          }
        }

        travelOrder.order = orders[travelOrder.orderId];
        // we don't have travel order number, so use ID for it
        travelOrder.number = travelOrder.id!;
      }
    }

    // collect facilities from order stops
    const facilities: Record<number, OrderFacilityDto> = {};
    for (const order of this.trip.orders) {
      for (const orderStop of order.orderStops) {
        facilities[orderStop.facilityId] = orderStop.facility!;

        if (orderStop.oldFacilityId) {
          if (!facilities[orderStop!.oldFacilityId]) {
            const res = await this.api.company.findFacilityById(orderStop!.oldFacilityId);
            if (res.success) {
              facilities[orderStop!.oldFacilityId] = {
                address: res.data!.address!,
                addressCoordinates: res.data!.addressCoordinates!,
                addressLine: res.data!.addressLine!,
                preciseCoordinates: res.data!.preciseCoordinates!,
                timezone: "",
                ...res.data,
                id: res.data!.id!,
                name: res.data!.name!,
                type: res.data!.type!,
              };
            }
          }
          orderStop.oldFacility = facilities[orderStop!.oldFacilityId];
        }
      }
    }

    // update facilities in route stops
    for (const route of routes) {
      for (let i = 0; i < route.routeStops.length; i++) {
        const stop = route.routeStops[i];
        stop.use = true;
        stop.editable = true;

        // add reload stop to trip stops
        if (stop.isReload && stop.type === OrderStopType.pickup) {
          /*const reloadStop = {
            type: OrderStopType.reload,
            isReload: true,
            facility: stop.facility,
            facilityId: stop.facilityId,
          };
          this.orderStops.splice(stop.position - 1, 0, reloadStop);*/
        }

        const orderStop = this.trip.orders.flatMap((o) => o.orderStops).find((os) => os.id === stop.orderStopId);
        const travelOrder = route.travelOrders.find((to) => to.orderId === stop.order?.id);
        if (travelOrder && this.isTravelOrderCancelled(travelOrder) && orderStop?.oldFacilityId) {
          stop.facilityId = orderStop!.oldFacilityId!;
          stop.facility = orderStop!.oldFacility!;
        } else if (orderStop?.state === OrderStopState.updated) {
          stop.facility = orderStop.facility;
          stop.oldFacilityId = stop.facilityId;
          stop.facilityId = stop.facility!.id;
        } else {
          stop.facility = facilities[stop.facilityId];
        }
      }

      // add not used route stops
      if (route.routeStops[0].position > 1) {
        for (let pos = 1; pos < route.routeStops[0].position; pos++) {
          route.routeStops.splice(0, 0, this.unusedRouteStop);
        }
      }
      for (let pos = route.routeStops[route.routeStops.length - 1].position; pos < maxStopsCount; pos++) {
        route.routeStops.push(this.unusedRouteStop);
      }

      this.recalculateRouteStops(route);
    }

    this.trip.routes = routes;
  }

  private get unusedRouteStop(): TripRouteStop {
    return {
      bolFiles: [],
      checkInTime: null,
      checkOutTime: null,
      editable: false,
      facilityId: 0,
      freights: [],
      isReload: false,
      loadUnloadTime: null,
      name: "",
      number: 0,
      oldFacilityId: null,
      order: null,
      orderStopId: null,
      otherFiles: [],
      podFiles: [],
      podSignedBy: null,
      position: 0,
      state: RouteStopState.normal,
      status: "",
      timeTo: "",
      timeType: "",
      timezone: "",
      type: "",
      use: false,
      verifyDispatcherId: null,
      verifyTime: null,
    };
  }

  private get isNewTrip(): boolean {
    return this.route.params.id === this.newTripSlug;
  }

  private async loadOrder(orderId: string): Promise<void> {
    let order: TripOrder | undefined;
    const resp = await this.api.orders.findOrderById(orderId);
    if (resp.success) {
      order = {
        ...resp.data!,
        files: [],
        freights: resp.data!.freights.map((f, i) => ({ ...f, number: i + 1 })),
      };
    }
    if (!order) {
      throw new Error("Order not found");
    }

    const fileResp = await this.api.orders.findOrderFiles(order.id!);
    if (fileResp.success) {
      order.files = fileResp.data!;
    }

    this.addOrder(order);
  }

  private async loadTruck(truckId: number): Promise<void> {
    const resp = await this.api.trucks.findTruckById(truckId);
    if (resp.success) {
      return this.addRoute(resp.data!);
    }
    throw new Error("Truck not found");
  }

  addOrder(order: TripOrder): void {
    delete order.polyline;
    this.trip.orders.push(order);

    // add travel orders to routes
    for (const route of this.trip.routes) {
      route.travelOrders.push(this.getTravelOrderFromOrder(order));
    }

    // add order stops
    for (const orderStop of order.orderStops) {
      orderStop.order = {
        id: order!.id!,
        number: order!.number!,
        status: order!.status!,
      };
      this.numerateOrderStops();

      // add order stop as route stop for all routes
      for (const route of this.trip.routes) {
        route.routeStops.push(this.buildRouteStopFromOrderStop(orderStop));
      }
    }
  }

  addRoute(truck: TruckDto | null = null, copyFreights = true) {
    const route: TripRoute = {
      distances: {},
      emptyMiles: null,
      freights: [],
      redispatch: "",
      status: "",
      totalDistance: 0,
      truck: truck,
      truckId: truck?.id,
      travelOrders: [],
      routeStops: [],
    };

    if (copyFreights) {
      route.freights = this.orders
        .flatMap((o) => o.freights)
        .map((f, index) => ({
          id: this.generateId(),
          freightId: f.id!,
          type: f.type!,
          plannedWeight: f.weight,
          plannedQuantity: f.quantity,
          length: f.length,
          width: f.width,
          height: f.height,
          stackable: f.stackable,
          number: index + 1,
        }));
    }

    let position = 1,
      pickUpNumber = 1,
      deliveryNumber = 1;
    for (const orderStop of this.trip.orders.flatMap((o) => o.orderStops)) {
      const stop = this.buildRouteStopFromOrderStop(orderStop, copyFreights);
      if (stop.type === OrderStopType.pickup) {
        stop.number = pickUpNumber++;
      } else if (stop.type === OrderStopType.delivery) {
        stop.number = deliveryNumber++;
      }
      if (copyFreights) {
        stop.freights = stop.freights.map((f) => route.freights.find((rf) => rf.freightId === f)!.id);
      }

      if (stop.type === OrderStopType.reload) {
        const deliveryReloadRouteStop = { ...stop, type: OrderStopType.delivery, isReload: true, position };
        this.generateStopName(deliveryReloadRouteStop);
        route.routeStops.push(deliveryReloadRouteStop);

        position++;

        const pickupReloadRouteStop = { ...stop, type: OrderStopType.pickup, isReload: true, position };
        this.generateStopName(pickupReloadRouteStop);
        route.routeStops.push(pickupReloadRouteStop);
      } else {
        route.routeStops.push(stop);
      }

      position++;
    }

    for (const order of this.trip.orders) {
      route.travelOrders.push(this.getTravelOrderFromOrder(order));
    }

    this.recalculateRouteStops(route);

    this.trip.routes.push(route);
  }

  mergeRouteFreights(route: TripRoute, freights: TripRouteFreight[]) {
    for (const routeFreight of freights) {
      const freight = route?.freights.find((f) => f.id === routeFreight.id);
      if (!freight) {
        route?.freights.push(routeFreight);
      } else {
        Object.assign(freight, routeFreight);
      }
    }
  }

  cancelTravelOrder(travelOrder: TravelOrderDto) {
    const order = this.orders.find((o) => o.id === travelOrder.orderId);
    if (order) {
      order.status = travelOrder.orderStatus;
    }
  }

  private buildRouteStopFromOrderStop(orderStop: TripOrderStop, copyFreights = true): TripRouteStop {
    const routeStop: TripRouteStop = {
      bolFiles: [],
      checkInTime: null,
      checkOutTime: null,
      loadUnloadTime: null,
      name: "",
      number: 0,
      oldFacilityId: null,
      otherFiles: [],
      podFiles: [],
      podSignedBy: null,
      position: 0,
      state: RouteStopState.normal,
      verifyDispatcherId: null,
      verifyTime: null,
      orderStopId: orderStop.id || null,
      order: {
        id: orderStop.order!.id!,
        number: orderStop.order!.number!,
        status: orderStop.order!.status!,
      },
      type: orderStop.type,
      facility: orderStop.facility,
      facilityId: orderStop.facilityId,
      freights: copyFreights ? [...orderStop!.freights!] : [],
      editable: this.trip.routes.length > 1,
      isReload: false,
      use: true,
      status: orderStop.status,
      note: orderStop.note,
      timezone: orderStop.timezone,
      timeType: orderStop.timeType,
      timeFrom: orderStop.timeFrom,
      timeTo: orderStop.timeTo,
      time2Type: orderStop.time2Type,
      time2From: orderStop.time2From,
      time2To: orderStop.time2To,
      dispatchedTimeType: orderStop.timeType,
      dispatchedTimeFrom: orderStop.timeFrom,
      dispatchedTimeTo: orderStop.timeTo,
    };
    this.generateStopName(routeStop);
    return routeStop;
  }

  private generateStopName<T>(stop: T & { name?: string; type?: string; isReload?: boolean; number?: number }): T {
    stop.name = stop.type === OrderStopType.pickup ? "Pick up" : "Delivery";
    if (stop.isReload) stop.name += " reload stop";
    if (stop.number) stop.name += " #" + stop.number;

    return stop;
  }

  private numerateOrderStops(): void {
    let position = 1;
    let deliveryNum = 1;
    let pickupNum = 1;

    for (const orderStop of this.trip.orders.flatMap((o) => o.orderStops)) {
      orderStop.position = position;
      position++;

      if (orderStop.type === OrderStopType.delivery) {
        orderStop.number = deliveryNum;
        deliveryNum++;
      }
      if (orderStop.type === OrderStopType.pickup) {
        orderStop.number = pickupNum;
        pickupNum++;
      }

      this.generateStopName(orderStop);
    }
  }

  private getTravelOrderFromOrder(order: TripOrder): TravelOrderDto {
    return {
      order: {
        id: order.id!,
        number: order.number!,
        status: order.status!,
      },
      orderId: order.id!,
      number: order.number!,
      status: TravelOrderStatus.notStarted,
    };
  }

  private generateId() {
    const nanoid = customAlphabet("1234567890abcdef", 16);
    return nanoid();
  }

  private recalculateRouteStops(route: TripRoute) {
    let position = 0,
      pickUpNumber = 1,
      deliveryNumber = 1;
    for (const stop of route.routeStops) {
      if (stop.type === OrderStopType.pickup) {
        stop.number = pickUpNumber++;
      } else if (stop.type === OrderStopType.delivery) {
        stop.number = deliveryNumber++;
      }
      stop.position = position++;
      this.generateStopName(stop);
    }
  }

  private isTravelOrderCancelled(travelOrder: TravelOrderDto): boolean {
    return TravelOrderCancellationStatusMap.some((s) => s.id === travelOrder.status);
  }
}
